import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { GetSales } from "../../services/Api";
import { useDispatch } from "react-redux";
import { imageBaseUrl } from "../../services/configure";
import { setSalestTeam } from "../../redux/features/counterSlice";
import { useTranslation } from 'react-i18next';

const MexicoSouthSlider = () => {
  const [sales, setSales] = useState([]);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation("global");

  useEffect(() => {
    const handleSales = async () => {
      try {
        const response = await GetSales();
        const sortedTeam = response.team.map(region => {
          const members = Array.isArray(region.members)
            ? region.members
            : Object.values(region.members);
    
          members.sort((a, b) => a.order_number - b.order_number);
          return { ...region, members };
        });    
        setSales(sortedTeam);
        dispatch(setSalestTeam(sortedTeam));
      } catch (error) {
      }
    };

    handleSales();
  }, [dispatch]);

  return (
    <>
      <div className="solution-slider slider-container partner-slide team-slider">
        <div className="slider-custom-container">
          {sales?.map((region, regionIndex) => {
            const memberCount = region.members?.length || 0;
            const settings = {
              dots: false,
              infinite: true,
              speed: 2000,
              slidesToShow: memberCount > 5 ? 5 : memberCount, // Show up to 5 slides or the number of members
              slidesToScroll: 1,
              autoplay: memberCount > 5, // Enable autoplay only if there are more than 5 members
              autoplaySpeed: 2000,
              pauseOnHover: true,
              responsive: [
                {
                  breakpoint: 1400,
                  settings: {
                    slidesToShow: memberCount > 5 ? 4 : memberCount,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 992,
                  settings: {
                    slidesToShow: memberCount > 5 ? 3 : memberCount,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                  }
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: memberCount > 5 ? 2 : memberCount,
                    slidesToScroll: 1,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 576,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                  }
                }
              ]
            };

            return (
              <div key={regionIndex}>
                <div className="team-region">
                  <h3>
                    {i18n.language === 'en' ? region.region_en : region.region_es}
                  </h3>
                  <div className="image-hover"></div>
                </div>

                <Slider {...settings}>
                  {region.members?.map((member, index) => (
                    <div className="image-overlay" key={index}>
                      <div className="image-hover">
                        <img
                          src={`${imageBaseUrl + member?.profile}`}
                          alt={member?.name}
                          className="w-100"
                        />
                        <div className="hover-cont">
                          <p><span>{t("Phone")}</span>{member?.telephone}</p>
                          <p><span>{t("Email")}</span>{member?.email}</p>
                          <p><span>{t("Region")}</span>{i18n.language === 'en' ? member?.states_covered_en : member?.states_covered_es}</p>
                          {/* <p><span>{t("Region")}</span>{member?.states_covered}</p>
                          <h4> {i18n.language === 'en' ? item.title_en : item.title_es}</h4> */}
                        </div>
                      </div>
                      <div className="overlay-top">
                        <div className="team-detail">
                          <h4>{member?.name}</h4>
                          <p>
                            {i18n.language === 'en' ? member?.position_en : member?.position_es}
                          </p>
                          <p>{i18n.language === 'en' ? member?.states_covered_en : member?.states_covered_es}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default MexicoSouthSlider;